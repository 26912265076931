import React from 'react';

function Header() {
	return (
		<header className="header">
			<div className="header-inner">
				<div className="header-title">
					<a href="/" className="site-title">
						Dancing Cloud Art
					</a>
				</div>

				<nav className="header-nav">
					<ul className="nav-list">
						<li>
							<a href="/">Home</a>
						</li>
						<li>
							<a href="/painting">Painting</a>
						</li>
						<li></li>
						<li></li>
						{/* Add more navigation items as needed */}
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;
