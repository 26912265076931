import React from 'react';

function Home() {
	return (
		<section id="home" className="copper-section">
			<img src="/images/Rose1.jpg" alt="Rose" className="full-width-image" />

			<div className="text-block">
				<p>
					I believe we cause ourselves to transform. We need to be the cause,
					the force, that pushes us toward transformation. I am aware of
					swimming in that process, as difficult as it is to discern, as it is
					imperceptible. I did not live the life of an artist, although that
					muse poked her head into my world and beckoned me many times. I did
					win a prize at the Canadian National Exhibition in 1962 when I was 9
					years old. And now, when I am sorting through my affairs and
					possessions, I am seeing there is possibly a body of work when I
					gather together all the dabbling and doodling I have done in sixty
					years. Putting it together in one place is the purpose of my website.
				</p>
				<p>
					The painting was of a mountain with snow at the peak. The sun was
					setting and I can still remember painting the reflection of it on the
					snow. When I went back to school in the September I was told my
					painting had won a prize and given a book. It was a book about
					Cheetahs. I dont know who chose it.
				</p>
			</div>
		</section>
	);
}

export default Home;
