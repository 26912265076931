import React, { useState, useEffect } from 'react';

function Painting() {
    const paintings = [
        { id: 1, src: '/images/1.jpg', title: 'Painting 1' },
        { id: 2, src: '/images/2.jpg', title: 'Painting 2' },
        { id: 3, src: '/images/3.jpg', title: 'Painting 3' },
        { id: 4, src: '/images/4.jpg', title: 'Painting 4' },
    ];

    const [lightbox, setLightbox] = useState({ open: false, src: '', title: '' });

    const openLightbox = (painting) => {
        setLightbox({ open: true, src: painting.src, title: painting.title });
    };

    const closeLightbox = () => {
        setLightbox({ open: false, src: '', title: '' });
    };

    // Close lightbox with Escape key
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeLightbox();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    return (
        <section id="painting" className="copper-section">
            <div className="text-block">
            <p>
                The paintings I create come about as a result of an investigation I make into colour. 
                I have a bit of a structure which comes about as the offspring of the primary colours, red, blue and yellow. 
                My first intention with painting was to build a vocabulary of colour harmonies.
                </p>
                <p>
                When I first started with oils on canvas I felt like a scientist, minding all levels. 
                I was as interested in the consistency of the substances used, the gesso, the pure turpentine, 
                the linseed oil and the pigment, as I was in the colour of the pigment, their origins, the symbolic values of each hue. 
                I also investigated the consistency up through the layers. 
                First layer is thin and increasingly adding the oil on upper layers. 
                For me, investigation is through experimentation. 
                The realm of painting is so much fun!
                </p>
                <p>
                As a meditation, the activity of painting is informative not only of the external world. 
                It is rich with inner world information too. There are layers here too. 
                </p>
                <p>

                I was guided by the wisdom of keeping my intentions simple as there seemed to be so much to learn. 
                I kept away from naturalistic images and focused on inner meanings.
                </p>
                <div className="gallery-grid">
                    {paintings.map((painting) => (
                        <div key={painting.id} className="painting" onClick={() => openLightbox(painting)}>
                            <img src={painting.src} alt={painting.title} />
                            <p>{painting.title}</p>
                        </div>
                    ))}
                </div>
                {lightbox.open && (
                    <div className="lightbox" onClick={closeLightbox} style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000, // Ensure it's on top
                    }}>
                        <img src={lightbox.src} alt={lightbox.title} style={{
                            maxWidth: '90%',
                            maxHeight: '80%',
                        }} />
                    </div>
                )}
            </div>
        </section>
    );
}

export default Painting;



{/* <p>
The paintings I create come about as a result of an investigation I make into colour. 
I have a bit of a structure which comes about as the offspring of the primary colours, red, blue and yellow. 
My first intention with painting was to build a vocabulary of colour harmonies.
</p>
<p>
When I first started with oils on canvas I felt like a scientist, minding all levels. 
I was as interested in the consistency of the substances used, the gesso, the pure turpentine, 
the linseed oil and the pigment, as I was in the colour of the pigment, their origins, the symbolic values of each hue. 
I also investigated the consistency up through the layers. 
First layer is thin and increasingly adding the oil on upper layers. 
For me, investigation is through experimentation. 
The realm of painting is so much fun!
</p>
<p>
As a meditation, the activity of painting is informative not only of the external world. 
It is rich with inner world information too. There are layers here too. 
</p>
<p>

I was guided by the wisdom of keeping my intentions simple as there seemed to be so much to learn. 
I kept away from naturalistic images and focused on inner meanings.
</p> */}